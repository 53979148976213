export default {
  MAX_ATTACHMENT_SIZE: 5000000,

    s3: {
      REGION: "us-east-1",
      BUCKET: "ucilibraries-notes-app-uploads"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://3gjotfpx43.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_FAPR2zW5d",
      APP_CLIENT_ID: "1itcdud4e0o3k93ji8lvo024tn",
      IDENTITY_POOL_ID: "us-east-1:a00a43ae-1e43-4113-8cad-d28e6823cdff"
    }
  };
  